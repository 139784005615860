import React from "react"
import { BaseBlockContent2 } from "@utils/richTextS"

const Content = ({ _rawBody }) => {
  return (
    <>
      <section className="content-section">
        <BaseBlockContent2 className={"page-content-body"} blocks={_rawBody} />
      </section>
    </>
  )
}

export default Content
