import React from "react"
import { graphql } from "gatsby"
import SEO from "@components/SEO"
import CorporativeHeader from "@components/global/CorporativeHeader"
import CorporativeFooter from "@components/global/CorporativeFooter"
import Hero from "@components/OurMethodologiesPage/Hero"
import Content from "@components/OurMethodologiesPage/Content"
import "@styles/Components/ourMethodologies.scss"
import Footer from "@components/global/Footer"
import RiskAssessment from "@components/global/RiskAssessment"

const SimplePage = ({ data: { sanityOurMethodologies } }) => {
  const meta = sanityOurMethodologies?.seo
    ? Object.entries(sanityOurMethodologies.seo).map(([key, value]) => {
        return { name: key, content: value }
      })
    : []

  return (
    <>
      {sanityOurMethodologies?.seo && (
        <SEO meta={meta} seo={sanityOurMethodologies?.seo} />
      )}
      <CorporativeHeader />
      {sanityOurMethodologies?.hero && (
        <Hero sanityPages={sanityOurMethodologies} />
      )}
      {sanityOurMethodologies?._rawContent && (
        <Content _rawBody={sanityOurMethodologies._rawContent} />
      )}
      <RiskAssessment />
      <Footer />
    </>
  )
}

export default SimplePage

export const query = graphql`
  query sanityOurMethodologies {
    sanityOurMethodologies(id: { eq: "-b81c183f-3407-5c6f-a4c5-d48065f4fcc1" }) {
      seo {
        title
        description
        keywords
      }
      hero {
        overline
        headline
        _rawSubheadline
        heroBackground {
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF])
              description
            }
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`
